import React, { useEffect } from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import { Icon } from '../icon/icon';
import { Routes } from '@/types';
import { SideMenu } from '@/components';
import { useWindowScroll } from '@/hooks/use-window-scroll';
import { useSideMenu } from '../side-menu/side-menu';
import cx from 'classnames';
import './styles.scss';

export const Header = ({ path }) => {
  const [scroll] = useWindowScroll();
  const isOpaque = path !== `${Routes.HOME}` || scroll.y > 0;
  const sideMenu = useSideMenu();
  const { close } = sideMenu;

  useEffect(() => {
    close();
  }, [path, close]);

  const menuItems = [
    Routes.LASER_VISION,
    Routes.WHY_US,
    Routes.TESTIMONIALS,
    Routes.CAMPAIGNS,
    Routes.FINANCING,
    Routes.ENGAGEMENT,
  ];

  return (
    <nav
      className={cx('header d-flex flex-column justify-content-center', {
        'header--opaque': isOpaque,
      })}
    >
      <div className="header__desktop d-none d-lg-flex container">
        <div className="header__section d-flex justify-content-between align-items-center">
          <Link to="/">
            <div className="header__logo h2 fw-bold text-gray-900 mb-0">stts</div>
          </Link>
          <div>
            <Link to="/" className="btn btn-light me-3">
              Кухни
            </Link>
            <Link to="/" className="btn btn-light me-3">
              Мебели
            </Link>
            <Link to="/" className="btn btn-primary">
              Контакти
            </Link>
          </div>
        </div>
      </div>

      <div className="header__mobile d-flex d-lg-none flex-column justify-content-around m-3 mb-0">
        <div className="d-flex justify-content-between">
          <div className="header__logomenu">
            <div
              className="mobile-menu-toggle d-inline-block p-1 me-2"
              role="button"
              title="Menu"
              onClick={() => sideMenu.open()}
            >
              <Icon name="list" className={cx(isOpaque ? 'text-black' : 'text-white')}></Icon>
            </div>
            <SideMenu menuItems={menuItems} opened={sideMenu.isOpen} onClose={() => sideMenu.close()} />
          </div>
        </div>
      </div>
    </nav>
  );
};
