import React, { useEffect, useRef, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { toast } from 'react-toastify';

const hasWindow = typeof window !== `undefined`;
const bootstrap = hasWindow && import('bootstrap');

import create from 'zustand';
import { Button } from '@/components';
import { getLuxorText, under40, over40 } from '@/utils/helpers';

export const usePersonalTestModal = create<any>((set) => ({
  isOpen: false,
  open: () => {
    return set(() => ({ isOpen: true }));
  },
  close: () => {
    return set(() => ({ isOpen: false }));
  },
}));

export const PersonalTestModal = ({ opened, onClose }) => {
  const { t, language } = useI18next();
  const [modal, setModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });

  const onSubmit = async (data) => {
    const { name, email, age, captcha } = data;

    const isOver40 = Number(age) > 40;
    const isBG = language === 'bg';
    const text = isOver40 ? over40(data, isBG) : under40(data, isBG);
    const hospitalName = t('g.title');

    const userResult = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        from: `${hospitalName} <office@luxorclinic.com>`,
        to: `${name} <${email}>`,
        text,
        subject: isBG ? 'Резултат от тест през www.lasercorrection.bg' : 'Test result from www.lasercorrection.bg',
        captcha,
      }),
    };

    const luxorFeedback = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: 'office@luxorclinic.com',
        // to: 'yshterev@gmail.com',
        from: `${name} <${email}>`,
        text: getLuxorText(data),
        subject: 'Направил тест през www.lasercorrection.bg',
        captcha,
      }),
    };

    setIsLoading(true);

    try {
      // await fetch(`http://localhost:8788/api/send-mail`, userResult);
      // await fetch(`http://localhost:8788/api/send-mail`, luxorFeedback);
      await fetch(`/api/send-mail`, userResult);
      await fetch(`/api/send-mail`, luxorFeedback);
    } catch {
      toast.error(t('g.failed-to-send'));
    } finally {
      setIsLoading(false);
      toast.success(t('g.sent'));
      modal?.hide();
    }
  };

  useEffect(() => {
    bootstrap?.then(({ Modal }) => {
      const modalEl = modalRef.current;
      const modal = new Modal(modalEl, { backdrop: true });

      setModal(modal);
    });
  }, []);

  useEffect(() => {
    const modalEl = modalRef.current as HTMLElement;
    if (!modalEl) return;

    modalEl.addEventListener('hidden.bs.modal', onClose);

    return () => modalEl.removeEventListener('hidden.bs.modal', onClose);
  });

  useEffect(() => {
    if (!modal) return;

    if (opened) {
      reset();
      modal?.show();
    } else {
      modal?.hide();
    }
  }, [opened, modal, reset]);

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex={-1}
      aria-labelledby={t('contacts.personal-test')}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-between mb-3">
              <h3>{t('contacts.personal-test')}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="content-modal__wrapper p-1">
              <div className="row row-cols-1 row-cols-lg-2">
                <div className="col">
                  <div className="mb-3">
                    <input
                      type="text"
                      className={cx('form-control', { 'is-invalid': errors.name })}
                      placeholder={t('contacts.name')}
                      {...register('name', { required: true })}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className={cx('form-control', { 'is-invalid': errors.phone })}
                      placeholder={t('contacts.phone')}
                      {...register('phone', { required: true })}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label d-block">{t('contacts.sex')}</label>
                    <div className="form-check d-inline-block me-3">
                      <input
                        type="radio"
                        role={'button'}
                        className={cx('form-check-input', { 'is-invalid': errors.sex })}
                        id="sexFormCheck1"
                        name="sex"
                        value="male"
                        {...register('sex', { required: true })}
                      />
                      <label className="form-check-label" htmlFor="sexFormCheck1">
                        {t('g.male')}
                      </label>
                    </div>
                    <div className="form-check mb-3 d-inline-block">
                      <input
                        type="radio"
                        role={'button'}
                        className={cx('form-check-input', { 'is-invalid': errors.sex })}
                        id="sexFormCheck2"
                        name="sex"
                        value="female"
                        {...register('sex', { required: true })}
                      />
                      <label className="form-check-label" htmlFor="sexFormCheck2">
                        {t('g.female')}
                      </label>
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className={cx('form-control', { 'is-invalid': errors.rightEye })}
                        placeholder={t('contacts.right-eye')}
                        {...register('rightEye', { required: true })}
                      />
                    </div>
                    <label className="form-label d-block">{t('contacts.dioptric-power')}</label>
                    <div className="form-check d-inline-block me-3">
                      <input
                        type="radio"
                        role={'button'}
                        className={cx('form-check-input', { 'is-invalid': errors.dioptricPower })}
                        id="dioptricPowerFormCheck1"
                        name="dioptricPower"
                        value="yes"
                        {...register('dioptricPower', { required: true })}
                      />
                      <label className="form-check-label" htmlFor="dioptricPowerFormCheck1">
                        {t('g.yes')}
                      </label>
                    </div>
                    <div className="form-check mb-3 d-inline-block">
                      <input
                        type="radio"
                        role={'button'}
                        className={cx('form-check-input', { 'is-invalid': errors.dioptricPower })}
                        id="dioptricPowerFormCheck2"
                        name="dioptricPower"
                        value="no"
                        {...register('dioptricPower', { required: true })}
                      />
                      <label className="form-check-label" htmlFor="dioptricPowerFormCheck2">
                        {t('g.no')}
                      </label>
                    </div>
                    <label className="form-label d-block">{t('contacts.eye-diseases')}</label>
                    <div className="form-check d-inline-block me-3">
                      <input
                        type="radio"
                        role={'button'}
                        className={cx('form-check-input', { 'is-invalid': errors.eyeDiseases })}
                        id="eyeDiseasesFormCheck1"
                        name="eyeDiseases"
                        value="yes"
                        {...register('eyeDiseases', { required: true })}
                      />
                      <label className="form-check-label" htmlFor="eyeDiseasesFormCheck1">
                        {t('g.yes')}
                      </label>
                    </div>
                    <div className="form-check mb-3 d-inline-block">
                      <input
                        type="radio"
                        role={'button'}
                        className={cx('form-check-input', { 'is-invalid': errors.eyeDiseases })}
                        id="eyeDiseasesFormCheck2"
                        name="eyeDiseases"
                        value="no"
                        {...register('eyeDiseases', { required: true })}
                      />
                      <label className="form-check-label" htmlFor="eyeDiseasesFormCheck2">
                        {t('g.no')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <input
                      type="email"
                      className={cx('form-control', { 'is-invalid': errors.email })}
                      placeholder={t('contacts.email')}
                      {...register('email', { required: true, validate: (value) => /\S+@\S+\.\S+/.test(value) })}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="number"
                      min={0}
                      className={cx('form-control', { 'is-invalid': errors.age })}
                      placeholder={`* ${t('contacts.age')}`}
                      {...register('age', {
                        required: true,
                        validate: (v) => Number(v) > 0,
                      })}
                    />
                  </div>
                  <div className="mb-3 mt-5">
                    <input
                      type="text"
                      className={cx('form-control', { 'is-invalid': errors.city })}
                      placeholder={t('contacts.city')}
                      {...register('city', { required: true })}
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="number"
                      className={cx('form-control', { 'is-invalid': errors.leftEye })}
                      placeholder={t('contacts.left-eye')}
                      {...register('leftEye', { required: true })}
                    />
                  </div>
                  <label className="form-label d-block">{t('contacts.combat-sports')}</label>
                  <div className="form-check d-inline-block me-3">
                    <input
                      type="radio"
                      role={'button'}
                      className={cx('form-check-input', { 'is-invalid': errors.combatSports })}
                      id="combatSportsFormCheck1"
                      name="combatSports"
                      value="yes"
                      {...register('combatSports', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="combatSportsFormCheck1">
                      {t('g.yes')}
                    </label>
                  </div>
                  <div className="form-check mb-3 d-inline-block">
                    <input
                      type="radio"
                      role={'button'}
                      className={cx('form-check-input', { 'is-invalid': errors.combatSports })}
                      id="combatSportsFormCheck2"
                      name="combatSports"
                      value="no"
                      {...register('combatSports', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="combatSportsFormCheck2">
                      {t('g.no')}
                    </label>
                  </div>
                  <label className="form-label d-block">{t('contacts.contact-lenses')}</label>
                  <div className="form-check d-inline-block me-3">
                    <input
                      role={'button'}
                      type="radio"
                      className={cx('form-check-input', { 'is-invalid': errors.contactLenses })}
                      id="contactLensesFormCheck1"
                      name="contactLenses"
                      value="yes"
                      {...register('contactLenses', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="contactLensesFormCheck1">
                      {t('g.yes')}
                    </label>
                  </div>
                  <div className="form-check mb-3 d-inline-block">
                    <input
                      role={'button'}
                      type="radio"
                      className={cx('form-check-input', { 'is-invalid': errors.contactLenses })}
                      id="contactLensesFormCheck2"
                      name="contactLenses"
                      value="no"
                      {...register('contactLenses', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="contactLensesFormCheck2">
                      {t('g.no')}
                    </label>
                  </div>
                </div>

                <div className="col mb-3">
                  <input
                    type="number"
                    className={cx('form-control', { 'is-invalid': errors.captcha })}
                    placeholder={t('g.captcha')}
                    {...register('captcha', { required: true, validate: (value) => value === '12' })}
                  />
                  <div className="form-text">{t('g.captcha-text')}</div>
                </div>
                <div className="col d-none d-md-block">&nbsp;</div>

                <div className="mb-3">
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    title={t('contacts.send')}
                    className={'text-uppercase shadow btn-success mt-2'}
                    onClick={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
