import React, { useEffect, useRef, useState } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Button } from '../button/button';
import { Icon } from '../icon/icon';
import { LanguageSwitch } from '../language-switch/language-switch';
import { NavLink } from '../nav-link/nav-link';
import { Routes } from '@/types';
import { usePersonalTestModal } from '../personal-test-modal/personal-test-modal';
import { useRequestInfoModal } from '../request-info-modal/request-info-modal';
import create from 'zustand';

const hasWindow = typeof window !== `undefined`;
const bootstrap = hasWindow && import('bootstrap');

export const useSideMenu = create<any>((set) => ({
  isOpen: false,
  open: () => {
    return set(() => ({ isOpen: true }));
  },
  close: () => {
    return set(() => ({ isOpen: false }));
  },
}));

export const SideMenu = ({ menuItems, opened, onClose }) => {
  const { t, language } = useI18next();
  const isBG = language === 'bg';
  const logoURLFix = isBG ? '' : '-en';

  const [menu, setMenu] = useState(null);
  const menuRef = useRef();
  const personalTestModal = usePersonalTestModal();
  const requestInfoModal = useRequestInfoModal();

  const handleOpenPersonalTest = () => {
    personalTestModal.open();
  };

  const handleOpenRequestInfo = () => {
    requestInfoModal.open();
  };

  useEffect(() => {
    bootstrap?.then(({ Offcanvas }) => {
      const menuEl = menuRef.current;
      const menu = new Offcanvas(menuEl);

      setMenu(menu);
    });
  }, []);

  useEffect(() => {
    if (!menu) return;

    if (opened) {
      menu?.show();
    } else {
      menu?.hide();
    }
  }, [opened, menu]);

  useEffect(() => {
    const menuEl = menuRef.current as HTMLElement;
    if (!menuEl) return;

    menuEl.addEventListener('hidden.bs.offcanvas', onClose);

    return () => menuEl.removeEventListener('hidden.bs.offcanvas', onClose);
  });

  return (
    <div className="offcanvas offcanvas-start" ref={menuRef} tabIndex={-1} aria-labelledby="Mobile Menu">
      <div className="offcanvas-header header__logo-wrapper p-3">
        <h5 className="offcanvas-title " id="offcanvasExampleLabel">
          <Link to="/">
            <img className="header__logo" src={`/img/logo-white${logoURLFix}.svg`} alt="Luxor Logo" />
          </Link>
        </h5>
        <button
          id="mobile-menu-toggle-button"
          type="button"
          className="btn-close btn-close-white"
          aria-label="Close"
          onClick={onClose}
        ></button>
      </div>
      <div className="offcanvas-body p-0">
        <div className="m-3">
          <Button
            title={t('g.phone')}
            icon={<Icon name="phone-fill" size={14} />}
            className="header__phone header__phone--black text-uppercase me-3 w-100"
          />
          <Link to={Routes.CONTACTS}>
            <Button title={t('header.contact-us')} className="text-uppercase shadow me-3 mt-3 w-100 btn-primary" />
          </Link>
          <Button
            aria-label={t('header.personal-test')}
            title={t('header.personal-test')}
            className="text-uppercase shadow me-3 mt-3 w-100 btn-danger"
            onClick={handleOpenPersonalTest}
          >
            {t('header.personal-test')}
          </Button>
          <Button
            aria-label={t('header.appointment')}
            title={t('header.appointment')}
            className="text-uppercase shadow me-3 mt-3 w-100 btn-green"
            onClick={handleOpenRequestInfo}
          >
            {t('header.appointment')}
          </Button>
        </div>
        <hr className="mt-5" />
        <div className="header__navlinks">
          {menuItems.map((item) => (
            <NavLink key={item} item={item} />
          ))}
        </div>
        <hr className="mt-3" />
        <LanguageSwitch className="ms-4 mt-3" />
      </div>
    </div>
  );
};
