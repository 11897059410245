import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import cx from 'classnames';

export const LanguageSwitch = ({ className = '' }) => {
  const { changeLanguage } = useI18next();

  return (
    <div className={cx('header__flags me-3', className)}>
      <div className="d-inline-block me-3" role="button" onClick={() => changeLanguage('bg')}>
        <img src="/img/flags/bg.svg" className="header__flags-link" alt="BG" width={31} height={23} />
      </div>
      <div className="d-inline-block" role="button" onClick={() => changeLanguage('en')}>
        <img src="/img/flags/en.svg" className="header__flags-link" alt="EN" width={31} height={23} />
      </div>
    </div>
  );
};
