import React from 'react';
import './home-hero.scss';

export const HomeHero = () => {
  return (
    <section className="home-hero bg-gray-200">
      <div className="home-hero__img-wrap">
        <img src="https://picsum.photos/id/203/800/1050?grayscale" alt="" className="home-hero__img" />
      </div>
      <div className="container centered position-absolute">
        <div className="row">
          <div className="col-6">
            <h1 className="display-2 fw-bold mb-4">Arcus. architecture studio Design.</h1>
            <p className="lead mb-6">
              Arcus is a reference on the architecture sector, which stands out for bold, avant-garde architecture
              projects.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
